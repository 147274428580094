/*** 

______         _              ______        ______               _                        ______                             _____         _                 _             _          
|  _  \       (_)             | ___ \       |  _  \             | |                       | ___ \                           |_   _|       | |               | |           (_)         
| | | |___ ___ _  __ _ _ __   | |_/ /_   _  | | | |_____   _____| | ___  _ __   ___ _ __  | |_/ / __ _ ______ _  __ _ _ __    | | ___  ___| |__  _ __   ___ | | ___   __ _ _  ___ ___ 
| | | / _ / __| |/ _` | '_ \  | ___ | | | | | | | / _ \ \ / / _ | |/ _ \| '_ \ / _ | '__| | ___ \/ _` |_  / _` |/ _` | '__|   | |/ _ \/ __| '_ \| '_ \ / _ \| |/ _ \ / _` | |/ _ / __|
| |/ |  __\__ | | (_| | | | | | |_/ | |_| | | |/ |  __/\ V |  __| | (_) | |_) |  __| |    | |_/ | (_| |/ | (_| | (_| | |      | |  __| (__| | | | | | | (_) | | (_) | (_| | |  __\__ \
|___/ \___|___|_|\__, |_| |_| \____/ \__, | |___/ \___| \_/ \___|_|\___/| .__/ \___|_|    \____/ \__,_/___\__,_|\__,_|_|      \_/\___|\___|_| |_|_| |_|\___/|_|\___/ \__, |_|\___|___/
                  __/ |               __/ |                             | |                                                                                           __/ |           
                 |___/               |___/                              |_|                                                                                          |___/            

***/
@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

body {
  font-family: "Urbanist", sans-serif;
}
h1 {
  font-family: "Urbanist", sans-serif;
  font-weight: bold;
  font-size: 36px;
}
h2 {
  font-family: "Urbanist", sans-serif;
  font-weight: bold;
  font-size: 28px;
}
h3 {
  font-family: "Urbanist", sans-serif;
  font-weight: bold;
  font-size: 22px;
}
h4 {
  font-family: "Urbanist", sans-serif;
  font-weight: bold;
  font-size: 18px;
}
h5 {
  font-family: "Urbanist", sans-serif;
  font-weight: bold;
  font-size: 14px;
}
h6 {
  font-family: "Urbanist", sans-serif;
  font-weight: bold;
  font-size: 12px;
}
p {
  font-family: "Poppins", sans-serif;
}
html {
  font-family: "Urbanist", sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  margin: 0;
  font-family: "Urbanist";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

.no-activity-tile {
  font-family: "Urbanist";
}

iframe {
  border: 0;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.d-block {
  display: block !important;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}
hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

.s-scrollelement {
  height: 600px;
  overflow-y: scroll;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
.row-cols-auto > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
}
.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}
.form-label {
  color: var(--Primary, #e6bd18);
  font-family: Zona Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 2px);
  padding-bottom: calc(0.375rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 2px);
  padding-bottom: calc(0.5rem + 2px);
  font-size: 1.09375rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 2px);
  padding-bottom: calc(0.25rem + 2px);
  font-size: 0.765625rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 12px 12px 12px 12px !important;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #eeeeee !important;
  background-clip: padding-box;
  border: none !important;
  border-radius: 12px !important;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  -ms-border-radius: 12px !important;
  -o-border-radius: 12px !important;
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  background-color: none !important;
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}

.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}

.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}

.form-control::-webkit-input-placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}

.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 2px;
  border-radius: 0;
  -webkit-transition: background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out,
    -webkit-box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1),
    -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.25, 1);
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    -webkit-box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1),
    -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.25, 1);
  transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1),
    transform 0.2s cubic-bezier(0.35, 0, 0.25, 1),
    background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  transition: box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1),
    transform 0.2s cubic-bezier(0.35, 0, 0.25, 1),
    background-color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    -webkit-box-shadow 0.3s cubic-bezier(0.35, 0, 0.25, 1),
    -webkit-transform 0.2s cubic-bezier(0.35, 0, 0.25, 1);
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(2px * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  border-radius: 4px;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(2px * 2));
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 4px;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(2px * 2));
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(2px * 2));
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(2px * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(2px * 2));
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: 4px;
}

.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: 4px;
}

.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(2px * 2));
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(2px * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 2px solid var(--bs-border-color);
  border-radius: 4px;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.form-select:focus {
  border-color: #00695c;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 105, 92, 0.25);
  box-shadow: 0 0 0 0 rgba(0, 105, 92, 0.25);
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.765625rem;
  border-radius: 4px;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
  border-radius: 4px;
}

[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 18px !important;
  height: 18px !important;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
  border-radius: var(--corner-radius-radius-6, 6px);
  border: 2px solid #000;
}
.form-check-input:focus {
  border: 2px solid #000 !important;
  outline: 0 !important;
  box-shadow: none !important;
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  -webkit-filter: brightness(90%);
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: none;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(0, 105, 92, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(0, 105, 92, 0.25);
}
.justify-space-between {
  justify-content: space-between;
}

.form-check-input:checked {
  background-color: #000 !important;
  border-color: #000 !important;
}

.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FFF'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #00695c;
  border-color: #00695c;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  -webkit-transition: background-position 0.15s ease-in-out;
  transition: background-position 0.15s ease-in-out;
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2300695C'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e");
}

.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}

.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled] + .btn,
.btn-check:disabled + .btn {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme="dark"]
  .form-switch
  .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  -webkit-box-shadow: 0 0 0 1px #fff, none;
  box-shadow: 0 0 0 1px #fff, none;
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, none;
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  -webkit-appearance: none;
  appearance: none;
  background-color: #00695c;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b3d2ce;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  -moz-appearance: none;
  appearance: none;
  background-color: #00695c;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b3d2ce;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(2px * 2));
  min-height: calc(3.5rem + calc(2px * 2));
  line-height: 1.25;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: 2px solid transparent;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transition: opacity 0.1s ease-in-out,
    -webkit-transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out,
    -webkit-transform 0.1s ease-in-out;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}

.form-floating > .form-control::-webkit-input-placeholder,
.form-floating > .form-control-plaintext::-webkit-input-placeholder {
  color: transparent;
}

.form-floating > .form-control::-moz-placeholder,
.form-floating > .form-control-plaintext::-moz-placeholder {
  color: transparent;
}

.form-floating > .form-control:-ms-input-placeholder,
.form-floating > .form-control-plaintext:-ms-input-placeholder {
  color: transparent;
}

.form-floating > .form-control::-ms-input-placeholder,
.form-floating > .form-control-plaintext::-ms-input-placeholder {
  color: transparent;
}

.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}

.form-floating > .form-control:not(:-moz-placeholder-shown),
.form-floating > .form-control-plaintext:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:not(:-ms-input-placeholder),
.form-floating > .form-control-plaintext:not(:-ms-input-placeholder) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: 4px;
}

.form-floating > .form-control:not(:-ms-input-placeholder) ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: 4px;
}

.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: 4px;
}

.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: 2px 0;
}

.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}

.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 5;
}
/* input[type="text"] {
  width: 100%;
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  margin: 0px 0;
  outline: none;
  padding: 10px;
  box-sizing: border-box;
  transition: 0.3s;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
} */

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: 2px solid var(--bs-border-color);
  border-radius: 4px;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 4px;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  border-radius: 4px;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-control,
.input-group:not(.has-validation)
  > .form-floating:not(:last-child)
  > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation
  > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(
    .form-floating
  ),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .form-control,
.input-group.has-validation
  > .form-floating:nth-last-child(n + 3)
  > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(2px * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.765625rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  -webkit-box-shadow: 0 0 0 0 rgba(var(--bs-success-rgb), 0.25);
  box-shadow: 0 0 0 0 rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  -webkit-box-shadow: 0 0 0 0 rgba(var(--bs-success-rgb), 0.25);
  box-shadow: 0 0 0 0 rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid,
.form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(var(--bs-success-rgb), 0.25);
  box-shadow: 0 0 0 0 rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid,
.input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.765625rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  -webkit-box-shadow: 0 0 0 0 rgba(var(--bs-danger-rgb), 0.25);
  box-shadow: 0 0 0 0 rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  -webkit-box-shadow: 0 0 0 0 rgba(var(--bs-danger-rgb), 0.25);
  box-shadow: 0 0 0 0 rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid,
.form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(var(--bs-danger-rgb), 0.25);
  box-shadow: 0 0 0 0 rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid,
.input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}
label {
  display: inline-block;
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.text-left {
  text-align: left !important;
}

/* end scrollbar */
.log-view {
  background: url(../src/img/login-image.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center left;
  width: 100%;
  height: 100%;
}

.w-200px {
  width: 93px;
}

/* Image Container */
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url(../src/img/login-background.png) center/cover no-repeat;
  flex: 3 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  position: relative;
}

/* Image Container */
.empty-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* background: url(../src/img/login-background.png) center/cover no-repeat; */
  flex: 3 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #f0f0f0; */
  position: relative;
}

.icon {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: cover;
}

/* Form Container */
.form-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: scroll;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  /* height: 100%; */
  overflow-x: hidden;
}

.modal-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: scroll;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow-x: hidden;
}



/* Form Styles */
form {
  width: 100%;
  /* max-width: 400px; */
  text-align: center;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

/* .image-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url(../src/img/login-background.png) center/cover no-repeat;
} */

.twitterSetup-surprise {
  width: 80%;
  margin: auto;
  text-align: start;
}

.btn50 {
  width: 51%;
}
.landing-head h1 {
  font-family: Urbanist;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 46px;
}

p.para {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: var(--text-greyscale-700, #616161);
}
button.tab-btn-signin {
  height: fit-content;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  background: white;
  border-radius: var(--Rectangle-Radius, 100px);
  border: 2px solid #000;
  padding: 8px 33px;
  justify-content: center;
  align-items: center;
}
button.tab-btn-signup {
  height: fit-content;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  background: white;
  border-radius: var(--Rectangle-Radius, 100px);
  border: 2px solid #000;
  padding: 8px 37px;
  justify-content: center;
  align-items: center;
  border: 2px solid #000;
  background: #000;
  color: #fff;
}
.w-94 {
  width: 94px;
}
/* Add this to your CSS file */
.account-switch {
  display: grid;
}
h2.sub-head {
  color: #000;
  font-family: Urbanist;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}
.long-btn-sign-up {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  background: white;
  border-radius: var(--Rectangle-Radius, 100px);
  padding: 10px 44px;
  justify-content: center;
  border: none;
}
.long-btn-sign-in {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  background: white;
  border-radius: var(--Rectangle-Radius, 100px);
  border: 2px solid #000;
  padding: 10px 44px;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  background-color: #000;
  color: #fff;
  width: 78%;
}
.long-btn-sign-in:hover {
  background-color: #262626;
}

.long-btn-sign-in-disabled {
  background-color: #7a7a7a;
}
.forget-password {
  color: #212121;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-decoration: none;
}
.inputWithIcon {
  position: relative;
}
.inputWithIcon input[type="text"] {
  padding: 12px 12px 12px 33px !important;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  color: black;
}
.inputWithIcon input[type="password"] {
  padding: 12px 12px 12px 44px !important;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  color: black;
}
.i {
  position: absolute;
  left: 1px;
  top: 14px;
  width: 42px;
}
small.account-f {
  color: #212121;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
button.backbtn {
  border: none;
  background: none;
  padding: 0;
}
/* sign up tab css */
.main-container {
  justify-content: center;
  align-items: center;
  align-self: stretch;
  display: flex;
  border-radius: 25px;
  background: #f7f7f7;
  cursor: pointer;
  gap: 8px;
  padding: 4px 0px;
}
.image-wrapper > div {
  align-items: center;
  border-radius: 25px;
  display: flex;
  height: fit-content;
  padding: 10px;
  border: 1px solid #eee;
}
.image-wrapper img {
  width: 28px;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  align-self: stretch;
}

.description-container {
  width: 71%;
}
.description-container > div {
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.title-log-card {
  color: black;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}
.description-log-card {
  color: var(--text-greyscale-700, #616161);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 22px; */
  margin: 0;
}

.img-2 {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}

.main-container + .main-container {
  margin-top: 25px;
}
/* sign up tab css end here */
/* aside */
.container {
  height: 100vh;
}
.side-nav,
.nav-menu {
  height: 100%;
}
.side-nav .nav-menu {
  list-style: none;
  padding: 40px 0;
  width: 200px;
  background-color: #3498db;
}
.side-nav .nav-item {
  position: relative;
  padding: 10px 20px;
}
.nav-item.active {
  background-color: #fff;
  box-shadow: 0px -3px rgba(0, 0, 0, 0.2), 0px 3px rgba(0, 0, 0, 0.2);
}
.nav-item.active a {
  color: #2980b9;
}
.nav-item a {
  text-decoration: none;
  color: #fff;
}
.menu-text {
  padding: 0 20px;
}
.side-nav .nav-item.active::before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 100%;
  right: 0;
  height: 150%;
  width: 20px;
  border-bottom-right-radius: 25px;
  box-shadow: 0 20px 0 0 #fff;
}
.side-nav .nav-item.active::after {
  content: "";
  position: absolute;
  background-color: transparent;
  top: 100%;
  right: 0;
  height: 150%;
  width: 20px;
  border-top-right-radius: 25px;
  box-shadow: 0 -20px 0 0 #fff;
}

/* .right-aside {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 300px; 
  background-color: #3498db;
  color: #fff;
} */

.content {
  padding: 20px;
}

.content h2 {
  font-size: 24px;
  margin: 0;
}

.content p {
  font-size: 16px;
}

.layout {
  display: flex;
  height: 100vh;
}

.conta {
  height: 100%;
  width: 267px;
  /* overflow-y: auto;  */
}

.content {
  flex: 1;
  padding: 20px;
  background-color: #f2f2f2;
}
/* Add styles for LeftAside */

.log-out-btn {
  background-color: unset;
  border: unset;
  padding-right: 0px;
  padding-left: 0px;
  padding: 0;

  color: #616161;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
}

.conta {
  height: 100%;
  /* width: 267px; */
  width: 21vw;
  /* overflow-y: auto; */
  position: fixed;
  border-right: 2px solid #c4c4c4;
  top: 0;
  left: 0;
  z-index: 1;
  transition: transform 0.3s;
}

.conta.open {
  transform: translateX(0);
}

/* Add styles for RightAside */
/* .right-aside {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background-color: #3498db;
  color: #fff;
  box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.2);
} */

/* Add styles for main content */
.main-content {
  margin: 0 250px; /* Adjust the margin to match the width of the left aside */
  padding: 20px;
  /* background-color: #f2f2f2; */
  flex: 1;
  transition: margin 0.3s;
  margin-bottom: 7rem;
}

/* Additional styles for active state */
.nav-item.active {
  background-color: #fff;
  box-shadow: 0px -3px rgba(0, 0, 0, 0.2), 0px 3px rgba(0, 0, 0, 0.2);
}

.nav-item.active a {
  color: #2980b9;
}

.side-nav .nav-item.active::before,
.side-nav .nav-item.active::after {
  content: "";
  position: absolute;
  background-color: transparent;
  right: -10px;
  top: -10px;
  bottom: -10px;
  width: 20px;
  border-radius: 25px;
  box-shadow: 0 10px 0 0 #fff;
}
/* Add styles for LeftAside */
.left-aside {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #3498db;
  color: #fff;
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.2);
}

/* Update styles for RightAside */
.right-aside {
  position: fixed;
  top: 0;
  right: 0;
  padding: 24px 10px 20px 10px;
  border-left: 2px solid #c4c4c4;
  /* width: 290px; */
  width: 21vw;
  height: 99%;
  color: #fff;
  overflow-y: auto;
}

.right-aside::-webkit-scrollbar {
  width: 0px; /* For Chrome, Safari, and Opera */
  background: transparent; /* Optional: just in case you want transparent scrollbar for webkit */
}

.right-aside {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.right-aside::-webkit-scrollbar-thumb {
  background: transparent;
}

.right-aside::-webkit-scrollbar-track {
  background: transparent;
}

/* Update styles for main content */
.main-content {
  /* margin-left: 267px; 
  margin-right: 267px;  */
  /* margin-left: 268px;
  margin-right: 289px; */
  margin-left: 21vw;
  margin-right: 21vw;
  padding: 20px;
  /* background-color: #f2f2f2; */
  flex: 1;
  transition: margin 0.3s;
}
/* LEFT */
.divison-left-aside {
  align-items: center;

  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}
h6.head-pills {
  width: 246px;
  border-radius: var(--Rectangle-Radius, 100px);
  border: 2px solid #000;
  background: #000;
  color: #fff;
  padding: 10px 30px 10px 30px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
}
/* css for payment page */
ul.payment-description-box {
  list-style: decimal;
}
.hidden-input {
  display: none;
}

.file-upload {
  cursor: pointer;
  background: #d9d9d9;
  border-radius: 50px;
  width: 100px;
  height: 100px;
  position: relative;
  align-content: center;
}

.profile-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.image-picker-icon {
  position: absolute;
  bottom: 33%;
  right: 33px;
}

/* css for search bar */
/* .wrapper {
  width: 100%;
  max-width: 31.25rem;
  margin: 6rem auto;
} */

.label {
  font-size: 0.625rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: +1.3px;
  margin-bottom: 1rem;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#search {
  width: 100%;
  height: 2.8rem;
  border-radius: 15px;
  background: #eee;
  outline: none;
  border: none;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#search-btn {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#search-btn:hover {
  cursor: pointer;
}
/* css for search page content */
.fighter-card {
  border-radius: 0;
  display: flex;
  gap: 2px;
}
/* css for activities page */
/* ul.nav-tabs {
  border: none;
} */
.nav-tabs {
  --bs-nav-tabs-border-width: none !important;
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg)
    var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color)
    var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid
    var(--bs-nav-tabs-border-color);
}
.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: #000 !important;
  text-decoration: none;
  background: 0 0;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.tab-cst-btn {
  display: flex;
  width: 150px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 25px;
  border: 1px solid #000;
}
.goal-activity-tab {
  display: inline-flex;
  color: #000;
  width: 140px;
  padding: 7px;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  border: 1px solid #000;
  color: #000;
  text-align: center;
  /* Fonts/h5 */
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.sub-activity-tab {
  display: inline-flex;
  width: 140px;
  color: #000;
  padding: 7px;
  margin-left: 18px;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  border: 1px solid #000;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.activity-nav .active {
  background-color: #000;
  color: white;
}
.surprise-activity-tab {
  display: inline-flex;
  width: 100px;
  color: black;
  padding: 7px;
  margin-left: 18px;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  border: 1px solid #000;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: 1px solid #000 !important;
  background: #000 !important;
  color: #fff !important;
}
.line-after {
  position: relative;
  overflow: hidden;
  display: block;
}

.line-after span {
  vertical-align: baseline;
  display: block;
  position: relative;
  color: #c4c4c4;
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
span.goal-amount {
  color: #000;
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
span.active-goal {
  color: #f00;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.complete-goal {
  color: #009106;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.line-after span:after {
  content: "";
  position: absolute;
  width: 100%;
  top: 50%;
  margin-left: 10px;
  border-top: 1px solid #ccc;
}
.goal-taskly {
  color: #aaa;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
a.follow-profile-btn {
  display: flex;
  width: 140px;
  padding: 3px 47px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 25px;
  border: 1px solid #212121;
  background: #000;
  color: #fff;
  text-decoration: none;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
button.follow-profile-btn {
  display: flex;
  width: 140px;
  padding: 3px 47px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 25px;
  border: 1px solid #212121;
  background: #000;
  color: #fff;
  text-decoration: none;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
/* css for cart page */

.addGoalToCart {
  text-decoration: none;
  border-style: none;
  cursor: pointer;
  color: #000;
  white-space: nowrap;
  justify-content: center;
  border-radius: var(--Rectangle-Radius, 100px);
  background-color: #c4c4c4;
  align-self: center;
  margin-top: 31px;
  width: 350px;
  max-width: 100%;
  padding: 11px 30px;
  font: 600 16px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}

.subscription-head {
  color: #000;

  /* Fonts/h3 */
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.crowd-contribution-amount > label {
  color: var(--Text-General-Text-Light, #212121);
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 125% */
}

.crowd-contribution-amount > input {
  border: 1px solid #969696;
  /* border-style: none; */
  outline: none;
  color: #000;
  white-space: nowrap;
  border-radius: 16px;
  justify-content: center;
  /* border-radius: var(--Rectangle-Radius, 100px); */
  background-color: #f5f5f5;
  align-self: center;
  margin-top: 31px;
  width: 224px;
  max-width: 100%;
  padding: 10px 17px;
  font: 600 14px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}

.addGoalToCart:hover {
  background-color: #a19f9f;
}

/* .crowd-contribution-amount > input ::placeholder {
  text-align: center;
} */

.addToCartCheckout {
  text-decoration: none;
  cursor: pointer;
  color: #fff;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: var(--Rectangle-Radius, 100px);
  background-color: var(--surface-general-black, #000);
  align-self: center;
  margin-top: 20px;
  width: 350px;
  max-width: 100%;
  padding: 11px 60px;
  border: unset;
  font: 600 16px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}

/* .addtocartcheckout:hover {
  background-color: #c54343;
} */

h6.user-call {
  color: #000;
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
p.user-recall {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.product-thumbnail {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 120px;
  overflow: hidden;
  border-radius: 9%;
  max-width: 100%;
}
.table > thead {
  vertical-align: middle !important;
  text-align: center;
}
.table > tbody {
  vertical-align: middle !important;
  text-align: center;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
  color: #666 !important;
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.t-data {
  color: var(--text-general-text-light, #212121);
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}
small.est {
  color: #666;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.Add-Message > button {
  border-style: none;
  border-radius: 17px;
  border: 2px solid #c4c4c4;
  background: #c4c4c4;
  padding: 5px 9px;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}

.add-quantity {
  border: none;
  background-color: unset;
}
.remove-quantity {
  border: none;
  background-color: unset;
}
.remove-prdct {
  border: none;
  background-color: inherit;
  color: #666;
  text-align: center;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
}

.update-quantity-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.9rem;
  justify-content: center;
}

.save-message-btn {
  border-radius: var(--Rectangle-Radius, 100px);
  border: 1px solid #000;
  background-color: #000;
  display: flex;
  padding: 7px 27px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  text-align: center;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.add-message-modal-body {
  margin: 0px 26px;
  /* border: 2px solid red; */
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.image-tittle {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: none;
  border-width: 0 !important;
  border: none;
}
.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
  flex-direction: column;
  align-items: center;
  border: 1px solid #212121;
  background: transparent !important;
  color: #000;
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.fs-14 {
  font-family: Poppins !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 28px;
}
.checkout-btn {
  width: 350px;
  border: 1px solid black;
  padding: 8px 2px 10px 0px;
  align-items: center;
  border-radius: var(--Rectangle-Radius, 100px);
  background: var(--surface-general-black, #000);
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
}
.pay-page-detail h6 {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
ul.terms-list {
  list-style: decimal;
}

@media (max-width: 991px) {
  .fighter-card {
    flex-wrap: wrap;
  }
}

.fighter-image {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 50px;
  overflow: hidden;
  border-radius: 50%;
  max-width: 100%;
}

.fighter-details {
  border-radius: 0;
  align-self: center;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  margin: auto 0;
  padding: 0 12px;
}
a.select-fighter {
  text-decoration: none;
}
span.cs-rank {
  align-self: center;
  color: #000;
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 30px; */
  padding: 0px 20px 0px 20px;
}
.fighter-name {
  color: #000;
  letter-spacing: 0;
  text-transform: capitalize;
  text-decoration: none;
  margin-right: -20px;
  white-space: nowrap;
  font: 600 18px/24px Urbanist, -apple-system, Roboto, Helvetica, sans-serif;
}
/* Example CSS for the alphabetical search sidebar */
.f-bar {
  padding: 0px 16px !important;
}
.alphabetical-search {
  background-color: #eeeeee;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.alphabet-list {
  list-style: none;
  padding: 0;
}

.alphabet-list li {
  margin-bottom: 5px;
}

.alphabet-list li span {
  cursor: pointer;
  text-decoration: none;
  color: #333;
  font-weight: bold;
  display: block;
  padding: 5px;
  border-radius: 3px;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
}

.alphabet-list li span:hover {
  background-color: #ddd;
}

@media (max-width: 991px) {
  .fighter-name {
    white-space: initial;
  }
}

.fighter-info {
  color: #616161;
  white-space: nowrap;
  margin: 5px -20px 0 0;
  font: 400 14px/22px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
  .fighter-info {
    white-space: initial;
  }
}

@media (max-width: 991px) {
  .div-2 {
    padding: 0 20px;
  }
}

@media (max-width: 991px) {
  .div-3 {
    white-space: initial;
  }
}
.div-4 {
  align-items: self-start;
  border-radius: var(--Rectangle-Radius, 100px);
  align-self: center;
  display: flex;
  margin-top: 10px;
  width: 138px;
  max-width: 100%;
  flex-direction: column;
  padding: 5px 14px;
}
.div-5 {
  justify-content: space-between;
  align-items: flex-start;
  border-radius: var(--Rectangle-Radius, 100px);
  align-self: stretch;
  display: flex;
  gap: 10px;
}
.my-link {
  color: #616161;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 24px; */
  text-decoration: none;
}
/* right content css */
a.link-text {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  text-decoration: none;
}
.card-grey {
  border-radius: 25px;
  background: #fafafa;
  padding: 13px 12px 13px 12px;
}
.card-head h5 {
  color: #000;
  font-family: Urbanist;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}
.celebrity-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
ul.celeb-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.celebrity-avatar {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 46px;
  max-width: 100%;
  border-radius: 50%;
}

.celebrity-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: auto 14px 0px 0px;
}

.celebrity-name {
  color: #000;
  font: 600 14px/137.5% Urbanist, -apple-system, Roboto, Helvetica, sans-serif;
  white-space: nowrap;
}

.celebrity-role {
  color: var(--text-greyscale-700, #616161);
  font: 400 14px/175% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
  margin: 3px 0px 0 0;
}

.follow-button {
  color: #fff;
  text-align: center;
  border: 1px solid #212121;
  background-color: #000;
  border-radius: 25px;
  /* width: 89px; */
  max-width: 100%;
  padding: 4px 17px 4px 17px;
  font: 600 14px/137.5% Urbanist, -apple-system, Roboto, Helvetica, sans-serif;
  white-space: nowrap;
  transition: background-color 0.3s, color 0.3s;
}

.follow-button:hover {
  background-color: #ffffff;
  color: #000;
}
.ranking {
  color: #000;
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  padding: 0px 7px 0px 40px;
}
.celebrity-rank-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: auto 14px 0px 0px;
}

@media (max-width: 991px) {
  .celebrity-name,
  .follow-button {
    white-space: initial;
  }
}
/* right content css end here  */
/* main */
.tile {
  position: relative;
  background: #ffffff;
  border-radius: 20px 20px 3px 3px;
  /*padding: 20px;
  */
  /* margin-bottom: 30px; */
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 20px 20px 3px 3px;
  -moz-border-radius: 20px 20px 3px 3px;
  -ms-border-radius: 20px 20px 3px 3px;
  -o-border-radius: 20px 20px 3px 3px;
}
.u-banner {
  aspect-ratio: 3.82;
  object-fit: cover;
  object-position: center;
  width: 100%;
  overflow: hidden;
  border-radius: 20px 20px 0px 0px;
}
.user-info {
  display: flex;
  /* align-items: center; */
  justify-content: space-between; /* To separate content and image */
  background: rgba(255, 255, 255, 0.8);
  /* padding: 10px; */
  border-radius: 5px;
  min-height: 12.5rem;
  /* margin-top: 10px; */
}

.user-content {
  display: flex;
  align-items: end;
}

.user-details {
  flex: 1; /* Allow this side to take available space */
  text-align: left;
  padding: 10px;
}

.user-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
}

.profilelink-copy-btn {
  /* padding: 10px; */
  margin-bottom: 2.6rem;
  cursor: pointer;
}
.profilelink-copy-btn:hover {
  scale: 1.1;
}

.img-2 {
  width: 20px;
  aspect-ratio: 1;
  object-fit: contain;
  align-self: center;
  max-width: 100%;
  margin: auto 0;
}
.sub-bt {
  background: #c4c4c4;
  padding: 15px 75px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 800 !important;
}
.sub-bt:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
.sub-bt-x {
  background: #c4c4c4;
  padding: 15px 100px;
  border-radius: 50px;
  font-size: 15px;
  font-weight: 800 !important;
}
.sub-bt-x:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
.sub-bt-cp {
  background: #c4c4c4;
  padding: 15px 100px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 800 !important;
}
.sub-bt-cp:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}
.acs-form {
  background: #000;
  color: #fff;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  padding: 8px 13px;
  justify-content: center;
  width: 330px;
  border-radius: 50px;
  border-style: dashed;
}
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 11px 14px 11px 14px !important;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: #eeeeee !important;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: none !important;
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: #eeeeee !important;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}
.accordion {
  --bs-accordion-border-radius: 10px !important;
  --bs-accordion-inner-border-radius: 10px !important;
}
/* accordian body */
.f-subs-thumbnail {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 120px;
  overflow: hidden;
  border-radius: 9%;
  max-width: 100%;
}
.fighter-card-body {
  gap: 20px;
}
.gift-status {
  flex: auto;
}
span.cost-n {
  color: #000;
  text-align: center;
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-right: 8px;
}
button#uncontrolled-tab-example-tab-profile-y {
  width: 150px;
  padding: 7px;
  margin-left: 18px;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  border: 1px solid #000;
  font-family: Urbanist;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.f-page-head h2 {
  font-family: Urbanist;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px;
}
.f-page-head p {
  color: #616161;
  font-family: Urbanist;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}
.inf-img {
  width: 320px;
  height: 320px;
  object-fit: cover; /* Adjust as per your requirement */
}
.help-page {
  text-align: -webkit-center;
}
a.email-button {
  padding: 10px 13px;
  border-radius: var(--Rectangle-Radius, 100px);
  border: 2px solid #000;
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-decoration: none;
}
a.call-button {
  padding: 10px 13px;
  border-radius: var(--Rectangle-Radius, 100px);
  border: 2px solid #212121;
  background: #212121;
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-decoration: none;
}
.btn-group {
  display: flex;
  justify-content: center;
  gap: 18px;
}
.works-thumbnail {
  width: 300px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1;
  overflow: hidden;
  border-radius: 9%;
  max-width: 100%;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.works-thumbnail:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}
.abt-img {
  object-fit: cover;
  object-position: center;
  aspect-ratio: 2;
  overflow: hidden;
  border-radius: 9%;
  max-width: 100%;
  height: 250px;
}
.cst-acc .accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 11px 14px 11px 14px !important;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: #fff !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 2px solid #9e9e9e !important;
  border-radius: none !important;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  color: #000;
  font-family: Urbanist;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  -webkit-transition: var(--bs-accordion-transition);
  -moz-transition: var(--bs-accordion-transition);
  -ms-transition: var(--bs-accordion-transition);
  -o-transition: var(--bs-accordion-transition);
  -webkit-border-radius: none !important;
  -moz-border-radius: none !important;
  -ms-border-radius: none !important;
  -o-border-radius: none !important;
}
.cst-acc.accordion-item {
  box-shadow: none !important;
  border: none !important;
}
.cst-acc.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: 0 !important;
  --bs-accordion-inner-border-radius: calc(
    var(--bs-border-radius) - (var(--bs-border-width))
  );
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23052c65%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}
.cst-acc.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

img.number-icon {
  width: 45px !important;
  /* height: 45px; */
  display: flex;
}
.w-head {
  gap: 12px;
  align-items: center;
}
.align-self-cntr {
  align-self: center;
}
.text-justify {
  text-align: justify;
}
/* acordian body */

/* 28-11-2023 */
/* css code added by Rahul  */

.user-profile-container {
  width: 8rem;
  height: 8rem;
  position: absolute;
  bottom: -51px;
  border-radius: 9.375rem;
  -webkit-border-radius: 9.375rem;
  -moz-border-radius: 9.375rem;
  -ms-border-radius: 9.375rem;
  -o-border-radius: 9.375rem;
  background-color: #e9e9e9;
}

.user-profile-container > img {
  object-fit: cover;
  border: 0.313rem solid #fff;
  background-color: #e9e9e9;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  -webkit-border-radius: inherit;
  -moz-border-radius: inherit;
  -ms-border-radius: inherit;
  -o-border-radius: inherit;
  position: relative;
}

.log-user-info-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 0px 10px 0px;
}

.fav-btn {
  text-decoration: none;
  padding: 0.5em 1.6em 0.5em 1.6em;
}

.social-icon-style {
  color: white;
  background-color: black;
  border-radius: 10rem;
  padding: 0.24rem;
  -webkit-border-radius: 10rem;
  -moz-border-radius: 10rem;
  -ms-border-radius: 10rem;
  -o-border-radius: 10rem;
}

.social-media-links:hover {
  scale: 1.1;
}

.social-picker {
  cursor: pointer;
}
.social-picker:hover {
  scale: 1.1;
}

.remove-social-btn {
  position: absolute;
  right: -9px;
  top: -15px;
  padding: 0;
  margin: 0;
  border: none;
  background-color: inherit;
}

.remove-social-btn:hover {
  scale: 1.1;
}

.sec-head {
  color: #000;
  font-family: Urbanist;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.875rem;
}

.user-link {
  opacity: 0.6;
}
.promotion-company {
  opacity: 0.6;
}

.profile-details {
  color: var(--text-general-text-light, #212121);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.tile-content {
  position: relative;
}

.user-handle {
  color: #000;
  font-family: Urbanist;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem; /* 137.5% */
  opacity: 0.6;
}

.filter-goal {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}

.filterLeft {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.sub-btn-fonts {
  font-family: Urbanist;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375px;
}

.category-btn {
  border-radius: 20px;
  border: 1px solid rgba(217, 217, 217, 0.85);
  background: #d9d9d9;
  width: 8.5rem;
  height: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightgoal {
  border-radius: 20px;
  border: 1px solid #212121;
  background-color: #000;
  color: #fff;
  padding: 0.625rem 0.938rem;
  width: 10rem;
  height: 2.4rem;
  width: 9rem;
  /* height: 2.563rem; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightgoal > a {
  text-decoration: none;
  color: inherit;
}

.filter-btn {
  cursor: pointer;
}

.category-dropdown {
  border-style: unset;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
}

.dropdown-item.active,
.dropdown-item:active {
  color: #000 !important;
  background-color: unset !important;
  /* Fonts/h6 */
  font-family: Urbanist;

  font-style: normal;
  font-weight: 600;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: black !important;
  background-color: unset !important;
  font-size: 16px;
}

.dropdown-item {
  color: #616161 !important;
  font-family: Urbanist !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
}

.goal-car-img {
  width: 14.375rem;
  height: 14.375rem;
  display: inline-block;
}

.gift-copy-btn {
  border-style: none;
  background-color: inherit;
}
.card-img-top {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  border: 1px solid rgba(217, 217, 217, 0.85);
}

.progress,
.prog {
  height: 0.625rem !important;
}

.goal-links {
  display: flex;
  justify-content: space-between;
}

.card-body {
  display: flex !important;
  flex-direction: column !important;
  gap: 5px !important;
}

.goal-links {
  display: flex;
  justify-content: space-between;
  margin-top: 0.4rem;
}

.goal-price-typo {
  color: #000;
  font-family: Urbanist;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 133.333% */
}
.text-dec-non {
  text-decoration: none !important;
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  background: #f3f7fa;
  border-radius: 10px;
  align-items: center;
  padding: 2.5px 5px;
}
.goal-title {
  color: var(--text-general-text-light, #212121);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
}

.goal-card {
  border-radius: 20px !important;
  border: 1px solid rgba(217, 217, 217, 0.85) !important;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  min-width: 14.375rem !important;
  min-height: 23.688rem;
  text-decoration: none !important;
}

.card-drag-icon {
  position: absolute;
  left: 0.6rem;
  top: 0.6rem;
  cursor: move;
  background-color: #e1e1e140;
  border-radius: 20px;
  display: flex;
}

.goal-card-section {
  display: flex;
  gap: 1.3rem;
  padding-bottom: 0.875rem;

  flex-wrap: wrap;
  gap: 10px;
}

.goal-card-section::-webkit-scrollbar {
  height: 8px; /* Set the width of the scrollbar */
}

.footer-fonts {
  color: #000;

  /* Fonts/body 2 */
  font-family: Poppins;
  font-size: 0.875em;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.footer-ul {
  display: flex;
  list-style: none;
  justify-content: space-between;
  margin: 0;
  padding: 0 0.5rem 0 0.5rem;
}

.footer-container {
  width: 57.5vw;
  margin-left: 21vw;
  margin-right: 21vw;
  position: relative;
  bottom: 15px;
  margin-top: 50vh;
}

.price-plan-subscription {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 198px;
}

.freq-btn {
  background-color: inherit;
  border-radius: 20px;
  height: 26px;
  border: 2px solid #000;
}

.medium-typo {
  color: #000;
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

#modal-container {
  border-radius: 20px !important;
  min-width: 576px !important;

  /* background-color: #fff; */
  /* -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important; */
}

.modal-content {
  text-align: center !important;
  padding: 1.563rem 0rem !important;
}

.social-btn-group {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  /* text-align: center; */
  align-items: center;
}

.save-message-container {
  display: flex;
  justify-content: end;
  gap: 1rem;
  align-items: center;
  margin-top: 1rem;
  padding: 0 26px;
}

.add-link-btn {
  border-style: solid;
  display: flex;
  padding: 3px 10px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: #fff;
  border-radius: 3.125rem;
  -webkit-border-radius: 3.125rem;
  -moz-border-radius: 3.125rem;
  -ms-border-radius: 3.125rem;
  -o-border-radius: 3.125rem;
  border-color: black;
}

.save-social-btn {
  background-color: #000;
  border-style: solid;
  padding: 4px 8px;
  border-radius: 3.125rem;
  width: 70%;
  -webkit-border-radius: 3.125rem;
  -moz-border-radius: 3.125rem;
  -ms-border-radius: 3.125rem;
  -o-border-radius: 3.125rem;
  color: white;
}

.all-btn:hover {
  background-color: #212121;
  border-color: #212121;
  color: #fff;
}

.socal-links {
  display: flex;
  margin: 0 4rem;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  padding: 8px 10.924px 12.294px 12px;
  flex-direction: column;
  gap: 0.5rem;
}

.socal-links .social-top {
  display: flex;
  justify-content: space-between;
}

#social-toggle {
  border-style: solid !important;
  border: 0.115rem solid gray !important;
  color: #212121;
  border-radius: 5px;
  padding: 6px 12px;
  background-color: inherit;
}

.amout-typography {
  color: var(--Text-General-Text-Light, #212121);
  text-align: right;
  font-family: Urbanist;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.socialink {
  display: hidden;
  opacity: 0;
}

.socialLablel {
  position: relative;
  width: 77px;
  height: 26px;
  display: inline-block;
  background: #d9d9d9d9;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.socialLablel:after {
  content: "";
  position: absolute;
  left: 0px;
  top: -1.5px;
  width: 28px;
  height: 28px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 1px 3px 6px #666666;
  transition: all 2s;
  -webkit-transition: all 2s;
  -moz-transition: all 2s;
  -ms-transition: all 2s;
  -o-transition: all 2s;
}

.socialink:checked + .socialLablel {
  background: grey;
}

.socialink:checked + .socialLablel:after {
  background: #000;
}

.socialink:checked + .socialLablel:after {
  left: auto;
  right: 0px;
  transition: right 3ms;
  -webkit-transition: right 3ms;
  -moz-transition: right 3ms;
  -ms-transition: right 3ms;
  -o-transition: right 3ms;
}

.social-link-item {
  display: flex !important;
  align-items: center !important;
  gap: 0.5rem !important;
  padding-left: 10px !important;
  color: #000 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.socal-links .social-bottom {
  border: 1px solid grey;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.socila-link-input-container {
  padding: 7px 10px;
  display: flex;
  align-items: center;
  gap: 0.7rem;
}

.terms-checkbox-input {
  accent-color: black;
}

.socila-link-input-container > input {
  outline: none;
  border: none;
}

.typo-grey-16 {
  /* color: var(--text-general-text-light, #212121); */
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem; /* 175% */
}
.typo-grey-16-600 {
  /* color: var(--text-general-text-light, #212121); */
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.125rem; /* 175% */
}

.typo-grey-14 {
  color: var(--text-general-text-light, #212121);
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 175% */
}
.typo-grey-12 {
  color: var(--text-general-text-light, #212121);

  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
}

.img-picker-container:hover {
  opacity: 0.5;
}
.img-picker-container {
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  border-radius: 50%;
  background-color: black;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
}

.img-picker-container > input {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  z-index: 100;
  border-radius: 50%;
}

.img-picker-container .picker-icon {
  color: white;
  position: absolute;
  top: 42%;
  display: flex;
  left: 35%;
  align-items: center;
  gap: 3px;
}

.banner-img-picker-background {
  position: absolute;
  top: 0;
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
}

.banner-img-picker-background:hover {
  opacity: 0.5;
}

.user-banner-image {
  position: relative;
  aspect-ratio: 3.82;
  object-fit: cover;
  object-position: center;
  width: 100%;
  background-color: #e9e9e9;
  border-radius: 22px 19px 0px 0px;
  -webkit-border-radius: 22px 19px 0px 0px;
  -moz-border-radius: 22px 19px 0px 0px;
  -ms-border-radius: 22px 19px 0px 0px;
  -o-border-radius: 22px 19px 0px 0px;
}

.banner-img-picker {
  width: 102px;
  position: absolute;
  right: 0;
  height: 50px;
  bottom: 0;
}
.banner-img-picker > input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  z-index: 999;
}
.banner-img-picker .picker-icon {
  position: absolute;
  color: white;
  bottom: 6px;
  right: 11px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.subscription-drop-container {
  position: absolute;
  right: 11px;
  top: 1px;
}
#subscription-dropdown-toggle {
  padding: 0 !important;
  border: unset !important;
}
#category-dropdown {
  padding: 0 !important;
  border: unset !important;
}
#filter-dropdown {
  padding: 0 !important;
  border: unset !important;
}

button#filter-dropdown::after {
  display: none !important;
}
button#category-dropdown::after {
  display: none !important;
}

.subscription-drop-down-list {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  min-width: fit-content !important;
}

.tab-content {
  position: relative !important;
}

.goal-img-upload {
  position: absolute;
  cursor: pointer;
  border-radius: 10px;
  border: 1px dashed;
  height: 143px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #eeeeee;
}

.goal-select-grp {
  display: flex;
  gap: 10px;
  align-items: center;
}

.goal-select-grp > input {
  width: 20px;
  height: 20px;
  accent-color: black;
  cursor: pointer;
}

.goal-select-grp > label {
  color: #212121;
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
}

.single-goal-content {
  display: flex;
  gap: 10px;
  margin-left: 31px;
  align-items: baseline;
}

.single-goal-content > input {
  accent-color: black;
}

.single-goal-content > label {
  display: flex;
  flex-direction: column;
}

.sub-goal-content {
  margin-left: 31px !important;
}

.sub-goal-content .sub-left,
.sub-right > div {
  display: flex;
  gap: 8px;
  align-self: baseline;
}

.sub-right .sub-right-inputpgrp > input,
.sub-left > input {
  accent-color: black;
}

.goal-container .crowd-fund > label {
  margin-left: 31px;
}

.goal-type-btn {
  border: none;
  background-color: inherit;
}

.goal-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.displaynameRanking {
  border: 1px solid #cbc9c9;
  border-radius: 13px;
  padding: 10px 15px 10px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.displaynameRanking .right {
  display: flex;
}

.surprise-gift-container {
  width: 100%;
  border: 1px solid #cbc9c9;
  border-radius: 13px;
  padding: 10px 15px 10px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: #fff;
  cursor: pointer;
}

.surprise-gift-container > label {
  cursor: pointer;
}

.giftmodal-body {
  margin: 28px 54px;
  /* border: 2px solid red; */
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.giftmodal-body .ceckbox-grup {
  display: flex;
  gap: 15px;
  align-items: center;
}

.giftmodal-body .ceckbox-grup > input {
  width: 20px;
  height: 20px;
  accent-color: black;
}

.giftmodal-body .MinAmount-grup {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.giftmodal-body .MinAmount-grup > input {
  border: 1px solid grey;
  border-radius: 10px;
  padding: 10px 7px 10px 16px;
  background-color: #eee;
  outline: none;
}

.delete-account {
  cursor: pointer;
  color: black;
}

.deletPassrdmodal-body {
  margin: 28px 54px;
  /* border: 2px solid red; */
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.deletPassrdmodal-body .password-grup {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.deletPassrdmodal-body .password-grup > div {
  display: flex;
  align-items: center;
  border: 1px solid grey;
  border-radius: 10px;
  padding: 10px 10px 10px 16px;
  background-color: #eee;
  /* outline: none; */
}

.deletPassrdmodal-body .password-grup > div > input {
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  margin-left: 5px;
}

.deletPassrdmodal-body .passwordmessage-grup {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.deletPassrdmodal-body .passwordmessage-grup > textarea {
  border: 1px solid grey;
  border-radius: 10px;
  padding: 10px 7px 10px 16px;
  background-color: #eee;
  outline: none;
}

.password-eye {
  cursor: pointer;
}

.deletPassrdmodal-success {
  text-wrap: pretty;
  margin: 30px 47px 30px 48px;
}
.x-auto-setupBtn {
  cursor: pointer;
}
.typeo-32-38 {
  font-family: Urbanist;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}
.typeo-18-24-u {
  color: #000;
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

.step-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.step-one-sub-body {
  border: 1px solid gray;
  margin: 0 61px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.step-one-sub-body > img {
  vertical-align: middle;
  width: 100%;
  border: 1px solid;
  border-color: inherit;
  border-radius: inherit;
}

.favorite-fighters-container {
  display: flex;
  flex-wrap: wrap;
}

.favorite-fighter {
  flex: 0 0 187px; /* Adjust the initial size of each fighter box */
  margin: 10px;
  text-align: center;
  border-radius: 20px;
  border: 1px solid rgba(217, 217, 217, 0.85);
  background: #fff;
  text-decoration: none;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.fighter-img-container {
  width: 100%; /* Adjust the width as needed */
  height: 12.063rem;
}

.fighter-img-container > img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  object-fit: cover;
}

.fav-fighter-name {
  padding: 9px 4px 14px 4px;
  color: #000;
  text-align: center;
  font-family: Urbanist;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.filter-goal .surprise-goal {
  display: flex;
  flex-direction: column;
  gap: 19px;
}
.surprise-goal > button {
  width: fit-content;
}

.MinAmount-grup > .send-gift-container {
  display: flex;
  align-items: center;
  background-color: #eee;
  border-radius: 13px;
  gap: 8px;
  padding: 12px 16px;
  color: #9e9e9e;
}
.MinAmount-grup > .send-gift-container > input,
textarea {
  width: 100%;
  border: none;
  outline: none;
  background-color: inherit;
}

.type-12-18-4 {
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  color: #616161;
}

.add-surprise-to-card-btn {
  background-color: #c4c4c4;
  border-color: #c4c4c4;
  border-style: solid;
  padding: 4px 8px;
  border-radius: 3.125rem;
  font-weight: 600;
  padding: 8px 0px;
  width: 70%;
  -webkit-border-radius: 3.125rem;
  -moz-border-radius: 3.125rem;
  -ms-border-radius: 3.125rem;
  -o-border-radius: 3.125rem;
  color: black;
}

.add-surprise-to-card-btn:hover {
  background-color: #b3b3b3;
}

.back-to-page-btn {
  cursor: pointer;
}
/* new change */
span.cal-info {
  opacity: 0.6;
  color: black;
}
.mt-1px {
  margin-top: 2px;
}
.active {
  color: black;
}

/* Styles for the SVG icon */
.showHideIcon {
  background-color: inherit;
  cursor: pointer;
  border: none;
}

.login-footer-page {
  width: 71vw;
  position: fixed;
  bottom: 5px;
  margin-left: 1vw;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  background-color: #000;
}

.login-footer-page > ul > li > a {
  text-decoration: none;
  color: white;
}

.footer-container > ul > li > a {
  color: #000;
  text-decoration: none;
  font-size: 12px;
}

.x-postbtn {
  gap: 0.4rem;
  border-style: none;
}

.addItemRemoveItemContainer {
  display: flex;
  justify-content: space-around;
}

.add-btn,
.remove-btn {
  cursor: pointer;
}

.password-input {
  height: 45px;
  display: flex;
  background-color: #eeeeee;
  align-items: center;
  padding: 0 15px;
  border-radius: 15px;
}
.email-input {
  height: 45px;
  display: flex;
  background-color: #eeeeee;
  align-items: center;
  padding: 0 0px;
  border-radius: 15px;
}
.email-input > input {
  border: none;
  background-color: inherit;
  margin-left: 7px;
  outline: none;
  margin-left: 7px;
  width: 100%;
  display: flex;
  height: 22px;
  align-items: center;
  /* padding: 12px 12px 12px 44px !important; */
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  color: black;
}

input:-webkit-autofill {
  background-color: #eeeeee;
  color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #eeeeee inset !important;
  box-shadow: 0 0 0px 1000px #eeeeee inset !important;
}

.password-input > input {
  border: none;
  background-color: inherit;
  margin-left: 7px;
  outline: none;
  margin-left: 7px;
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  /* padding: 12px 12px 12px 44px !important; */
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  color: black;
}

/* .if-password[type="password"] {
  font-size: 30px !important;
} */

/* .if-password[type="password"]:-webkit-autofill {
  font-size: 14px !important;
  
} */

.term-policy {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  margin: 10px 5px;
  flex-direction: row;
  gap: 6px;
  overflow: inherit;
}

.user-signup-form {
  background-color: white;
}

.user-enter-form {
  background-color: white;
}

.your-self-bg {
  background-color: white;
}

.term-policy > label {
  font-size: 14px;
}
.term-policy > input {
  width: 16px;
  height: 16px;
  accent-color: black;
  margin-top: 7px;
}
.already-have-account {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
.already-have-account > label {
  font-size: 14px;
}

@media (max-width: 1366px) {
  .term-policy {
    gap: 5px;
  }

  .term-policy > input {
    margin-top: 1px;
  }
  .term-policy > label {
    font-size: 12px;
    line-height: 16px;
  }
}

.error-message {
  color: red;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
}

.error-border {
  border-radius: 15px;
  border: 0.125rem solid red;
  background-color: white;
}
.error-border-profile {
  /* border-radius: 15px; */
  border: 1px solid red !important;
  /* background-color: white; */
}
.profile-error-message {
  /* display: flex; */
  align-items: center;
  margin-top: 6px;
  font-size: 14px;
  font-weight: 400;
  color: red;
  font-family: "Poppins", sans-serif;
}

.custom-spinner-style {
  position: absolute;
  top: 50%;
  left: 48%;
}

/* css for not found page  start */

.notFound-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
  animation: colorSlide 15s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;

  .second-container {
    text-align: center;
    h1,
    h3 {
      margin: 10px;
      cursor: default;

      .fade-in {
        animation: fadeIn 2s ease infinite;
      }
    }

    .return-toHome {
      border: 1px solid white;
      background: transparent;
      outline: none;
      padding: 10px 20px;
      font-size: 1.1rem;
      font-weight: bold;
      color: white;
      text-transform: uppercase;
      transition: background-color 200ms ease-in;
      margin: 20px 0;

      &:hover {
        background-color: white;
        color: #555;
        cursor: pointer;
      }
    }
  }
}

/* .view-msg {
  border-radius: 10px;
  background: #f7f7f7;
} */

.x-post-btn > a {
  border-radius: 10px;
  background: #e8e8e8;
  padding: 0.5px 4px;
  display: flex;
  align-items: center;
}

.title-h1 {
  font-size: 8em;
  transition: font-size 200ms ease-in-out;
  border-bottom: 1px dashed white;

  span#digit1 {
    animation-delay: 200ms;
  }
  span#digit2 {
    animation-delay: 300ms;
  }
  span#digit3 {
    animation-delay: 400ms;
  }
}

.goal-file-wrapper {
  position: relative;
  height: 143px;
}

.goal-preve-image {
  border-radius: initial;
  -webkit-border-radius: initial;
  -moz-border-radius: initial;
  -ms-border-radius: initial;
  -o-border-radius: initial;
}

.goal-sub-desc-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.goal-subdescription {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.checkout-done {
  width: 19.5rem;
  height: 100%;
  position: relative;
  border-radius: 20px;
  background-color: var(--color-white);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 25px 20px 27px;
  box-sizing: border-box;
  gap: 15px 0;
  text-align: center;
  font-size: 24px;
  color: var(--color-black);
  font-family: var(--fonts-h5);
}

.success-checkout-icon {
  width: 180.9px;
  height: 165px;
  position: relative;
}

.contribution-successful {
  position: relative;
  line-height: 30px;
  text-wrap: nowrap;
  text-align: center;
  font-family: Urbanist;
  font-size: 22px;
  font-weight: 700;
}

.proced-to-stripe {
  /* display: flex; */
  width: 20.75rem;
  height: 2.813rem;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 10px;
  border: #000;
  background: var(--Surface-General-Black, #000);
  border-radius: var(--Rectangle-Radius, 100px);
}

.proced-to-stripe:hover {
  border: #141414;
  background: var(--Surface-General-Black, #141414);
}

.x-post-btn > button {
  border-radius: 10px;
  background: #e8e8e8;
  padding: 0.5px 4px;
  display: flex;
  align-items: center;
  border: unset;
}

.setting-hr-container {
  display: flex;
  /* width: 100%; */
  justify-content: center;
  align-items: center;
  gap: 13px;
  margin: 0.9rem 0;
}
.setting-hr-container > hr {
  width: 100%;
}
.setting-hr-container > span {
  color: #aaa;
  font-family: Urbanist;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.slected-filter {
  background-color: #ebebeb !important;
  color: black !important;
  font-weight: 700 !important;
}

@keyframes colorSlide {
  0% {
    background-color: #152a68;
  }
  25% {
    background-color: royalblue;
  }
  50% {
    background-color: seagreen;
  }
  75% {
    background-color: tomato;
  }
  100% {
    background-color: #152a68;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* css for not found page  end */

/* css for spinner start*/

.fighter-home-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
} /* css for spinner end*/

/* chat modal css start */

.messanger {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.messanger .messages {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 10px 0;
  padding: 0 1.2rem;
  max-height: 260px;
  min-height: 260px;
  overflow-y: auto;
  overflow-x: hidden;
}
.messanger .messages .message {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.messanger .messages .message.me {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.messanger .messages .message.me img {
  margin-right: 0;
  margin-left: 15px;
}
.messanger .messages .message.me .info {
  background-color: #00695c;
  color: #fff;
}
.messanger .messages .message.me .info:before {
  display: none;
}
.messanger .messages .message.me .info:after {
  position: absolute;
  right: -13px;
  top: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 16px 16px 0;
  border-color: transparent #00695c transparent transparent;
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.messanger .messages .message img {
  border-radius: 50%;
  margin-right: 15px;
  width: 45px;
  height: 45px;
}
.messanger .messages .message .info {
  margin: 0;
  background-color: #ddd;
  padding: 5px 10px;
  border-radius: 3px;
  position: relative;
  -ms-flex-item-align: start;
  align-self: flex-start;
}
.messanger .messages .message .info:before {
  position: absolute;
  left: -14px;
  top: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 16px 16px 0;
  border-color: transparent #ddd transparent transparent;
}
.messanger .sender {
  padding: 0 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

p.message-error {
  position: absolute;
  top: -11px;
  left: 32px;
  padding: 0 4px;
  background-color: white;
  font-size: smaller;
  color: red;
}
.messanger .sender input[type="text"] {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border: 1px solid #028071;
  outline-color: #026257;

  padding: 5px 10px;
}
.messanger .sender button {
  border-radius: 0;
}
#chat-modal-container {
  max-width: 464px;
}
#chat-modal-container > div {
  border-radius: 14px;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
  padding: 1rem 0rem !important;
}

.messanger-modal-body .tile-title {
  text-align: start;
  margin-left: 1rem;
}

.siginParent-position {
  width: 100%;
  position: absolute;
  top: 20%;
}
/* 
@media (max-width: 1161px) {
  .siginParent-position {
    position: absolute;
    top: 23%;
    left: 6%;
  }
}
@media (max-width: 1387px) {
  .siginParent-position {
    position: absolute;
    top: 23%;
    left: 12%;
  }
} */

.green {
  color: #5cb85c !important;
}
/* chat modal css end */

.sign-ingroup-icon {
  width: 185.9px;
  position: relative;
  height: 180px;
  object-fit: contain;
}
.sign-in-successful {
  align-self: stretch;
  position: relative;
  line-height: 30px;
  font-weight: 600;
}
.sign-in-please-wait {
  margin: 0;
}
.sign-in-please-wait-you-container {
  align-self: stretch;
  position: relative;
  font-size: Poppins;
  line-height: 28px;
  font-family: Urbanist;
  color: #616161;
  font-size: 1rem;
}
.sign-in-successful-parent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
}
.sign-in-group-icon1 {
  width: 35px;
  position: relative;
  height: 34.7px;
  animation: rotateAnimation 1s linear infinite;
  -webkit-animation: rotateAnimation 1s linear infinite;
}

.h-100 {
  height: 100% !important;
}

.scroll-y-100 {
  height: 100vh !important;
  overflow-y: scroll !important;
}

.padding-20 {
  padding: 3rem 0rem !important;
}

.goal-priceInput {
  background: inherit;
  outline: none;
  border: none;
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* css for Sigin loader modal start*/
.sign-in-group-parent {
  margin: auto;
  width: 18rem;
  border-radius: 25px;
  background-color: #fff;
  height: -moz-fit-content;
  height: fit-content;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  text-align: center;
  gap: 2px;
  font-size: 1.5rem;
  color: #000;
  font-family: Urbanist;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

p.sign-in-please-wait {
  font-size: 0.9rem;
}

.background-fade {
  background-color: #e9e9e9 !important;
}

.faq-font {
  font-family: poppins;
}

.subscribabel-drop-conatiner {
  border: 1px solid black;
  border-radius: 14px;
  padding: 4px 6px 4px 5px;
  max-width: fit-content;
}

select#subscribabel-select {
  border: unset !important;
  border-radius: unset !important;
  text-align: center !important;
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
  -webkit-border-radius: unset !important;
  -moz-border-radius: unset !important;
  -ms-border-radius: unset !important;
  -o-border-radius: unset !important;
  display: block;
  width: 100%;
  padding: unset !important;
  background-color: inherit !important;
  appearance: auto !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.w-60 {
  width: 60%;
}

/* extra css for extra siginup form */

.your-self-signup {
  height: 100vh;
  overflow-y: scroll;
  margin-top: 2rem;
}

.auth-p-icon {
  position: absolute;
  top: 36%;
  left: 34%;
}

.companion-compelte {
  background-color: black;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  margin: auto;
}

.reset-btn {
  border: unset;
  background-color: inherit;
  color: #009106;
}

button.head-pills {
  width: 246px;
  border-radius: var(--Rectangle-Radius, 100px);
  border: 2px solid #000;
  background: #000;
  color: #fff;
  padding: 10px 30px 10px 30px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
}
/* alok new */
.w-nowrap {
  white-space: nowrap;
}
.checkout-btn:hover {
  width: 350px;
  border: 1px solid black;
  padding: 8px 2px 10px 0px;
  align-items: center;
  border-radius: var(--Rectangle-Radius, 100px);
  background: #fff;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
  color: #000;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.align-baseline {
  align-items: baseline;
}

/* LeftAside.css */

.mobile-toggle {
  position: sticky;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.btn-hover:hover{
  border-color: #1e1e1e;
  background-color: #1e1e1e;
}

.close-toggle {
  position: absolute;
  display: none;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.breadcrumb-icon {
  display: none;
  font-size: 20px;
}

/* .breadcrumb-icon.open {
  transform: rotate(90deg);
} */
.d-lg-none {
  display: none;
}

.cart-table {
  white-space: nowrap;
}
.socal-links {
  display: flex;
  margin: 0 4rem;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  padding: 40px 10.924px 40.294px 12px;
  flex-direction: column;
  gap: 0.5rem;
}
.remove-social-btn {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 0;
  margin: 0;
  border: none;
  background-color: #000;
  padding: 0px 5px 5px 7px;
  border-radius: 0px 0px 0px 10px;
}

.empty-bannerImage {
  position: relative;
  min-height: 13rem;
  background-color: #e9e9e9;
  border-radius: 22px 19px 0px 0px;
  -webkit-border-radius: 22px 19px 0px 0px;
  -moz-border-radius: 22px 19px 0px 0px;
  -ms-border-radius: 22px 19px 0px 0px;
  -o-border-radius: 22px 19px 0px 0px;
}

.goal-status {
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  margin: auto;
  padding: 2px 8px;
  line-height: 18px;
}

/*
tooltip code :start
*/

/* src/Tooltip.css */
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-trigger {
  cursor: pointer;
}

.tooltip-content {
  position: absolute;
  bottom: 88%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 3px 6px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 0.7rem;
  z-index: 1;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s;
}

/*tooltip code end */

/* css for flag  start*/

button.flag-close-btn {
  position: absolute;
  top: -25px;
  right: 0px;
}

.flag-modaBody {
  margin: 12px 2px;
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}



.p-relative{
  position: relative;
}


.pass-visibility {
  position: absolute;
  right: 4px;
  bottom: 14px;
}

@media (min-width: 576px) {
  .flag-modaBody {
    margin: 0.2rem 1.5rem;
  }
}

button.flag-btn {
  width: 30%;
  margin: auto;
  background-color: black;
  border-radius: 17px;
  border: 1px solid black;
  padding: 7px 0px;
  color: white;
}

button.flag-btn:hover {
  background-color: #272727;
}

.stripe-connectMsg {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.custom-toast-container .Toastify__toast {
  background-color: #000 !important;
}

.hide-scrollbar {
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

/* css for flag  start end*/
